import React, { useState, useEffect } from 'react'
import Fuse from 'fuse.js'
import {useHistory} from 'react-router-dom'
import Cards from '../components/Cards'
import Loading from '../components/Loading'
import Filter, { FilterChange } from '../components/filter'
import { Empty } from 'antd';
import API from '../services/api'
import { Recording, Recordings } from '../types'
import { MdLabel } from "react-icons/md";
var client = new API()

const fuseOptions = {
  keys: [
    {
      name: 'title',
      weight: 1
    }
  ]
}

export default function RecordingsView () {
  const [isLoading, setIsLoading] = useState(true);
  const [elements, setElements] = useState<Array<any>>([]);
  const [recordings, setRecordings] = useState<Recordings>([]);
  const [fuse, setFuse] = useState<any>(new Fuse([], fuseOptions));
  const history = useHistory();
  const options = [
    {value: 'all', text: 'All'},
    {value: 'consulting', text: 'Consulting'},
    {value: 'm&a', text: 'M&A'},
    {value: 'corporate_finance', text: 'Corporate Finance'}
  ]

  useEffect(() => {
    const fetchMyAPI = async () => {
      let recordings : Recordings = await client.getVideos()
      recordings.forEach((recording : Recording) => {
        recording.image = `https://img.youtube.com/vi/${recording.videoId}/mqdefault.jpg`
      })
      setElements(recordings)
      setRecordings(recordings)
      fuse.setCollection(recordings)
      setIsLoading(false)
    }
    fetchMyAPI()
  }, [])

  const goToRecording = (item : Recording) => {
    history.push(`/recordings/${item.id}`)
  }

  const handleChange = (res : FilterChange) => {
    if(typeof res.search == 'string') {
      if(!res.search) setElements(fuse._docs)
      else setElements(fuse.search(res.search).map((x : any) => x.item))
    }
  }

  if (isLoading) return <Loading/>
  return <div>
    <Filter handleChange={handleChange} search={true} ></Filter>
    {!elements.length
      ? <Empty description={'No Recordings Available'}/>
      : <Cards 
          cards={elements} 
          click={goToRecording}
          tags={[{field: 'tags', icon: <MdLabel/>, type: 'array'}]}
          />
    }
  </div>
}