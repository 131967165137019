import React from 'react'
import { Row, Col, Card } from 'antd'
import {useHistory} from 'react-router-dom'
import ScrollableList from '../ScrollableList'

interface Props {
		loading: boolean
		items: Array<any>
}

const data = [
	{text:'Link to facebook', id: '0'},
	{text: 'Link to website', id: '1'},
	{text:'Link to speaker', id: '2'},
	{text: 'Link to whatever', id: '3'},
	{text:'Link to whatever', id: '4'},
	{text: 'Link to whatever', id: '5'},
];

export default function Links (props: Props) {
	return (
		<Row justify="center" style={{height: "100%"}}>
			<Col span={24}>
				<Row style={{height: "100%"}}>
					<Card title={'Links'} style={{ width: "100%", height:"100%"}} loading={props.loading}>
						<ScrollableList items={data} empty={'No Links Available'} maxHeight={150}/>
					</Card>
				</Row>
			</Col>
		</Row>
	)
}