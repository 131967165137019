import * as React from 'react'
import { Row, Result, Button } from 'antd'
import {useHistory} from 'react-router-dom'
import { EventBus } from '../EventBus'

export default function Settings () {
	const history = useHistory()
	const back = () => {
		history.push('/')
		EventBus.$emit('changeTitle')
	}
  return (
    <div>
      <Row justify="center">
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={<Button type="primary" onClick={back}>Back</Button>}
      />
      </Row>
    </div>
  )
}