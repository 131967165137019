import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import Loading from '../../components/Loading'
import { PageHeader, Row } from 'antd';
import API from '../../services/api'
import MyForm from '../../components/RegistrationForm'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import {UserData} from '../../components/RegistrationForm'
var client = new API()

export default function UserInfo () {
  const [isLoading, setIsLoading] = useState(true);
  const [element, setElement] = useState<UserData>();
	const history = useHistory();
	const breakpoint = useBreakpoint();

  useEffect(() => {
    const fetchMyAPI = async () => {
			let userData : UserData = await client.getUserData()
			setElement(userData)
      setIsLoading(false)
    }
    fetchMyAPI()
  }, [])
	
	const save = (userInfo : any) => {
		client.saveUserInfo(userInfo)
	}

	if (isLoading) return <Loading/>
	return <div>
		<PageHeader
			className="site-page-header"
			onBack={() => history.push(`/account/settings`)}
			title={'Settings'}
			style={{ marginTop: -25, marginLeft: -25}}
		/>
		<Row justify="center" style={{padding: breakpoint.md ? "20px 100px 0px ": "20px 20px 0px"}}>
			<MyForm save={save} userData={element}></MyForm>
		</Row>
	</div>
}

