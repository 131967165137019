import cookies from "./cookies";
var firebase = require('firebase/app');
require('firebase/auth')
require('firebase/analytics')
require('firebase/database')

var firebaseConfig = {
    apiKey: "AIzaSyDzjqKfdWotXPVVvzvfNShY3JBi7rujEw0",
    authDomain: "capitant-app.firebaseapp.com",
    databaseURL: "https://capitant-app.firebaseio.com",
    projectId: "capitant-app",
    storageBucket: "capitant-app.appspot.com",
    messagingSenderId: "130446074648",
    appId: "1:130446074648:web:883419d5ea54354056b46d",
    measurementId: "G-GLKT9HTZCE"
  };
firebase.initializeApp(firebaseConfig);
firebase.analytics()

interface credentials {
	email: string | undefined;
	password: string | undefined;
}

export default {
	getUser: async () => {
		return firebase.auth().currentUser
	},
	signup: async (credentials : credentials, firstName : string, lastName : string) => {
		firebase.auth()
		.createUserWithEmailAndPassword(credentials.email, credentials.password)
		.then(async (res : any) => {
			firebase.auth().currentUser.sendEmailVerification()
			await firebase.auth().currentUser.updateProfile({
				displayName: `${firstName} ${lastName}`
			})
			// res.user.
			let idTokenResult = await res.user.getIdTokenResult()
			cookies.setCookie('user', idTokenResult)
			window.open('/', '_self')

		}).catch(function(error : any) {
			// Handle Errors here.
			console.log(error)
			return false
		})
	},
	login: async (option: string | undefined, credentials : credentials) => {
		let provider
		switch(option) {
			case 'Email':
				firebase.auth().signInWithEmailAndPassword(credentials.email, credentials.password).then(async (res : any) => {		
					console.log('hello')			
					let idTokenResult = await res.user.getIdTokenResult()
					cookies.setCookie('user', idTokenResult)
					window.open('/', '_self')

				}).catch(function(error : any) {
					// Handle Errors here.
					console.log(error)
				})
				break
			case 'Facebook':
				provider = new firebase.auth.FacebookAuthProvider();
				firebase.auth().signInWithRedirect(provider);
				break
			case 'Google':
				provider = new firebase.auth.GoogleAuthProvider();
				firebase.auth().signInWithRedirect(provider);
				break
			case 'Microsoft':
				provider = new firebase.auth.OAuthProvider('microsoft.com');
				firebase.auth().signInWithRedirect(provider)
				break
			default:
				break
		}
	},
	redirect: () => {
		return firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
			return firebase.auth().getRedirectResult().then((result : any) => {
				if(result.user) {
					result.user.getIdTokenResult().then(async (idTokenResult : any) => {
						await cookies.setCookie('user', idTokenResult)
						window.open('/', '_self')
					})
				}
			})
			.catch(function(error : any) {
				console.log(error)
			})
		}).catch((error :any)=> {

		})
	},
	signOut: () => {
		firebase.auth().signOut().then(async () => {
			await cookies.removeCookie('user')
			window.open('/login', "_self")
		}).catch(function(error : any) {

		})
	},
	onAuthStateChanged: async () => {
		firebase.auth().onAuthStateChanged(function (user : any) {
			let callback : any = null
			let metadataRef : any = null
			if (callback) {
				metadataRef.off('value', callback)
			}
			if (user) {
				// Check if refresh is required.
				metadataRef = firebase.database().ref('metadata/' + user.uid + '/refreshTime')
				callback = (snapshot : any) => {
					user.getIdToken(true)
				}
				// Subscribe new listener to changes on that node.
				metadataRef.on('value', callback)
				firebase.auth().currentUser.getIdTokenResult().then((idTokenResult : any) => {
					cookies.setCookie('user', idTokenResult)
					// Confirm the user is an Admin.
					// if (idTokenResult.claims) {
					// 	user.claims = idTokenResult.claims
					// } else {
					// 	console.log('cant acces jwt')
					// }
				})
				return false
			} else {
				// precaution
				cookies.removeCookie('user')
			}
		})
	},
	forgotPassword: async (email : string) => {
		firebase.auth().sendPasswordResetEmail(email).then(function() {
			console.log('email sent')
			// Email sent.
		}).catch(function(error : any) {
			console.log(error)
			// An error happened.
		});
	},
	changePassword: async (currentPassword : string, newPassword : string) => {
		await reauthenticate(currentPassword).then(async () => {
			var user = firebase.auth().currentUser;
			await user.updatePassword(newPassword).then(() => {
				console.log("Password updated!")
			}).catch((error : any) => { 
				throw error;
			})
		}).catch((error :any) => { 
			throw error;
		})
	}
}

const reauthenticate = (currentPassword : string) => {
  var user = firebase.auth().currentUser;
  var cred = firebase.auth.EmailAuthProvider.credential(
      user.email, currentPassword);
  return user.reauthenticateWithCredential(cred);
}