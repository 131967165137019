import { Cities } from "./types";

export const cities : Cities = [
	{value: 'all', text: 'All', optional: true},
	{value: 'ghent', text: 'Ghent', optional: false},
	{value: 'leuven', text: 'Leuven', optional: false},
	{value: 'brussels', text: 'Brussels', optional: false},
	{value: 'antwerp', text: 'Antwerp', optional: false},
	{value: 'liege', text: 'Liège', optional: false},
]

export const certificateRules = [
	{
		name: 'bronze', 
		title: 'Bronze Certificate',
		color: '#6A3805',
		requirements: [
			{name: 'lecture', value: 1},
			{name: 'inhouseday', value: 2}
		]
	},
	{
		name: 'silver', 
		title: 'Silver Certificate',
		color: '#B4B4B4',
		requirements: [
			{name: 'lecture', value: 2},
			{name: 'inhouseday', value: 1},
			{name: 'businesscase', value: 1},
			{name: 'workshop', value: 1}
		]
	},
	{
		name: 'gold', 
		title: 'Gold Certificate',
		color: '#AF9500',
		requirements: [
			{name: 'lecture', value: 4},
			{name: 'inhouseday', value: 2},
			{name: 'businesscase', value: 1},
			{name: 'workshop', value: 1},
			{name: 'trip', value: 1}
		]
	}
]

export const studiesOptions = [
	{
		value: 'Bachelor',
		label: 'Bachelor',
		children: [
			{
				value: 'Economics',
				label: 'Economics',
				children: [
					{
						value: 'Economic Sciences',
						label: 'Economic Sciences',
					},
					{
						value: 'Applied Economics',
						label: 'Applied Economics',
					},
					{
						value: 'Business Engineering',
						label: 'Business Engineering',
					},
					{
						value: 'Business Administration',
						label: 'Business Administration',
					},
					{
						value: 'Management',
						label: 'Management',
					},
					{
						value: 'Marketing',
						label: 'Marketing',
					},
					{
						value: 'Accountancy',
						label: 'Accountancy',
					},
					{
						value: 'Other',
						label: 'Other',
					}
				],
			},
			{
				value: 'Law',
				label: 'Law',
			},
			{
				value: 'Other',
				label: 'Other',
			}
		],
	},
	{
		value: 'Master',
		label: 'Master',
		children: [
			{
				value: 'Economics',
				label: 'Economics',
				children: [
					{
						value: 'Economic Sciences',
						label: 'Economic Sciences',
					},
					{
						value: 'Applied Economics',
						label: 'Applied Economics',
					},
					{
						value: 'Business Engineering',
						label: 'Business Engineering',
					},
					{
						value: 'Business Administration',
						label: 'Business Administration',
					},
					{
						value: 'Management',
						label: 'Management',
					},
					{
						value: 'Marketing',
						label: 'Marketing',
					},
					{
						value: 'Accountancy',
						label: 'Accountancy',
					},
					{
						value: 'Other',
						label: 'Other',
					}
				],
			},
			{
				value: 'Law',
				label: 'Law',
			},
			{
				value: 'Other',
				label: 'Other',
			}
		],
	},
];