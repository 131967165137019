import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import Loading from '../components/Loading'
import { Empty, PageHeader, Row } from 'antd';
import API from '../services/api'
import {Recording} from '../types'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
var client = new API()


export default function Event () {
  const [isLoading, setIsLoading] = useState(true);
  const [element, setElement] = useState<any>();
	const history = useHistory();
	var { id } : {id : string} = useParams()
	const breakpoint = useBreakpoint()
	useEffect(() => {
		const fetchMyAPI = async () => {
			let video : Recording = await client.getVideo(id)
			setElement(video)
			setIsLoading(false)
		}
		fetchMyAPI()
	}, [id])

	const getUrl = () => {
		return "https://www.youtube.com/embed/" + element.videoId
	}

	if (isLoading) return <Loading/>
	if (!element.id) return <Empty  description={'No Recording'}/>
	return <div>
		<PageHeader
			className="site-page-header"
			onBack={() => history.push(`/recordings`)}
			title={element.title}
			style={{ marginTop: -25, marginLeft: -25}}
		/>
		<Row justify="center" style={{padding: 50}}>
			<iframe width={breakpoint.xs ? 250 : 550} height={breakpoint.xs ? 200 : 320}
				title={element.id}
				src={getUrl()} 
				frameBorder="0" 
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
				allowFullScreen
			></iframe>
		</Row>
	</div>
}

