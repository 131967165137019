import React from "react";
import { BackTop } from 'antd'


export default function BackToTop () {
  const style = {
    height: 40,
    width: 40,
    lineHeight: '40px',
    borderRadius: 4,
    backgroundColor: '#1088e9',
    textAlign: 'center' as const,
    color: '#fff',
    fontSize: 14,
  };

	return <BackTop visibilityHeight={400}><div style={style}>UP</div></BackTop>
}