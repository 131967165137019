import React, { Children } from "react";
import { Card, Image, Row, Col } from 'antd';
import Logo from '../assets/logo_black.png';

const { Meta } = Card;

interface Props {
	image?: string,
	title: string,
	description?: string
	roundImage ?: boolean
	imagePlaceholder ?: string
	children?: any
}

export default function DynamicCardContent (props : Props) {  
	return (
		<div style={{display: 'flex'}}>
			<Row align="middle" style={{width: '100%'}}>
				<Col span={24} md={props.roundImage ? 5 : 7}>
					{props.image 
						? <Image
							style={{
								borderRadius: props.roundImage ? '50%' : 8,
								overflow: 'hidden'
							}}
							src={props.image} 
							width={'100%'} 
							preview={false}
						/>
						: <Image 
							src={props.imagePlaceholder || Logo} 
							style={{
								borderRadius: props.roundImage ? '50%' : 8,
								overflow: 'hidden'
							}}
							width={'100%'} 
							preview={false}
						></Image>
					}
				</Col>
				<Col span={24} md={props.roundImage ? 19 : 17} style={{padding: 20}}>
					<div className={'ant-card-meta-title'}>
						{props.title}
					</div> 
					<div>
						{props.children}
					</div>
					<div className={'ant-card-meta-description'}>
						{props.description}
					</div>
					{/* <Meta
						title={props.title}
						description={props.description}
					/> */}
				</Col>
			</Row>
		</div>
	)
}


  
