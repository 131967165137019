import * as React from 'react'
import { Row, Col, Card} from 'antd'
import {useHistory} from 'react-router-dom'

interface Props {
    loading: boolean
}

export default function CertificateProgress (props: Props) {
  return (
		<Row justify="center" style={{height: "100%"}}>
			<Col span={24}>
				<Row style={{height: "100%"}}>
						<Card style={{ width: "100%", height:"100%"}} loading={props.loading}>
						</Card>
				</Row>
			</Col>
	</Row>
)
}