import * as React from 'react'
import { Row, Col, Card, Empty } from 'antd'
import {useHistory} from 'react-router-dom'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

interface Props {
    loading: boolean
}

export default function Video (props: Props) {
	const history = useHistory()
	const breakpoint = useBreakpoint()
	const back = () => {
		history.push('/')
	}
  return (
		<Row justify="center" style={{height: "100%"}}>
			<Col span={24}>
				<Row style={{height: "100%"}}>
					<Card title={'video'} style={{ width: "100%", height:"100%"}} loading={props.loading}>
						{true ?
							<Empty  description={'No Video Available'}/>
							: (<Row justify="center">
									<iframe width={breakpoint.xs ? 250 : 550} height={breakpoint.xs ? 200 : 320}
										title={'test'}
										src={'https://www.youtube.com/embed/gpo2WKRhenA'} 
										frameBorder="0" 
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
										allowFullScreen
									></iframe>
								</Row>)
						}
						</Card>
				</Row>
			</Col>
		</Row>
	)
}