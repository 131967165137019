import React, { useState, useEffect } from 'react'
import Cards from '../../components/Cards'
import Loading from '../../components/Loading'
import { Empty } from 'antd'
import API from '../../services/api'
import { useHistory } from 'react-router'
import {Event, Events} from '../../types'
const client = new API()

export default function AttendedEvents () {
  const [isLoading, setIsLoading] = useState(true);
  const [elements, setElements] = useState<Array<any>>([]);
  const history = useHistory();

  useEffect(() => {
    const fetchMyAPI = async () => {
      let events : Events = await client.getAttendedEvents()
      setElements(events)
      setIsLoading(false)
    }
    fetchMyAPI()
  }, [])

  const goToEvent = (item : Event) => {
    history.push(`/events/${item.id}`)
  }

  if (isLoading) return <Loading/>
  if (!elements.length) return <Empty  description={'No Events Yet'}/>
  return <Cards cards={elements} click={goToEvent}></Cards>

}