import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import Loading from '../components/Loading'
import { Empty, PageHeader, Row, Col } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import API from '../services/api'
import Files from '../components/event/Files';
import Description from '../components/event/Description'
import Video from '../components/event/Video'
import MeetTheSpeakers from '../components/event/MeetTheSpeakers'
import Links from '../components/event/Links'
import { Event } from '../types'
var client = new API()

export default function EventView () {
  const [isLoading, setIsLoading] = useState(true);
  const [event, setEvent] = useState<Event>();
	const history = useHistory();
	var { id } : {id : string} = useParams()
	const breakpoint = useBreakpoint()
  useEffect(() => {
		// makes sure the page starts at the top
		// document.body.scrollTop = 0
		// document.documentElement.scrollTop = 0
		window.scrollTo(0,0);
		// 
    const fetchMyAPI = async () => {
      let event : Event = await client.getEvent(id)
      setEvent(event)
      setIsLoading(false)
    }
    fetchMyAPI()
  }, [id])

	if (!event && !isLoading) return <Empty description={'No Event Available'}/>
	return <div>
		<PageHeader
			className="site-page-header"
			onBack={() => history.push(`/events`)}
			title={event ? event.title : ''}
			style={{ marginTop: -25, marginLeft: -25}}
		/>
			<Row gutter={20} >
				<Col span={24} md={16}>
					<Row className={'dashboard-row'}>
						<Col span={24}>
							<div style={{height: "100%"}}>
								<Description 
									title='Description' 
									description={event ? event.description : ''} 
									loading={isLoading}
								/>
							</div>
						</Col>
					</Row>
					<Row className={'dashboard-row'}>
						<Col span={24}>
							<div style={{height: '100%'}}>
								<MeetTheSpeakers loading={isLoading}></MeetTheSpeakers>
							</div>
						</Col>
					</Row>
				</Col>
				<Col span={24} md={8}>
					<Row className={'dashboard-row'}>
						<Col span={24}>
							<div style={{height: "100%"}}>
								<Links items={[]} loading={isLoading}></Links>
							</div>
						</Col>
					</Row>
					<Row className={'dashboard-row'}>
						<Col span={24}>
							<div style={{height: '100%'}}>
								<Files items={[]} loading={isLoading}></Files>
							</div>
						</Col>
					</Row>
				</Col>
			</Row>
			<Row gutter={20}>
			<Col span={24} md={24}>
				<Row className={'dashboard-row'}>
				<Col span={24}>
					<div style={{height: '100%'}}>
						<Video loading={isLoading}/>
					</div>
				</Col>
				</Row>
			</Col>
		</Row>
	</div>
}

