import React, { useState, useEffect } from 'react'
import { Layout, Menu, Popover, Avatar, Grid, Drawer, BackTop} from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  CalendarOutlined,
  VideoCameraOutlined,
  UserOutlined,
  BankOutlined,
  AlertOutlined,
  AppstoreOutlined
} from '@ant-design/icons';
import { Link, useHistory} from 'react-router-dom'
import Logo from '../assets/logo_white.png';
import SquareLogo from '../assets/square_logo_white.png';
import routes from '../routes'
import { EventBus } from '../EventBus'
import auth from '../services/auth';
import BackToTop from './BackToTop';

const { useBreakpoint } = Grid;

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;

const getTitle = () => {
  // find the current route in routes
  let title = routes.find(x => x.path === window.location.pathname)?.title

  // little ugly trick
  if (!title) {
    let arr = window.location.pathname.split('/')
    if (arr.length > 2) {
      arr[arr.length - 1] = ':id'
      title = routes.find(x => x.path === arr.join('/'))?.title
    } 
  }
  if(!title) {
    title = 'Not Found'
  }
  return title 
}

interface Props {
  sider?: Boolean,
  children: any
}

const defaultProps = {
  sider: true,
  children: null
}

export default function MySider (props : Props) {
  props = {...defaultProps, ...props}
  const [user, setUser] = useState<any>();
  const [title, setTitle] = useState<string | undefined>(getTitle() );
  const [selectedKey, setSelectedKey] = useState(window.location.pathname)
  const [isToggled, setToggled] = useState(false);
  const [isMenuToggled, setMenuToggled] = useState(false);
  const breakpoint = useBreakpoint()
  const history = useHistory()

  const fetchUser = async () => {
    let user = await auth.getUser()
    console.log(user)
    setUser(user)
  }

  useEffect(() => {
    setTimeout(() => {
      fetchUser()
    }, 1000);
    // Eventbus that will be executed with EvenBus.$emit()
    EventBus.$on('changeTitle', (title : string) => {
      if (title) setTitle(title)
      else setTitle(getTitle())
    })
  }, [])

  const toggleTrueFalse = () => setToggled(!isToggled)

  const changeRoute = (route : string) => {
    // find the current route in routes.js
    let title : string | undefined = routes.find(x => x.path === route)?.title
    //change title and set highlighted tab
    setTitle(title)
    setSelectedKey(route)
    // push route after title change
    history.push(route)
    // close drawer or top right menu
    if (!breakpoint.lg) setToggled(true)
    setMenuToggled(false)
  }
  
  const logout = async () => {
    auth.signOut()
  }

  const content = (
    <div>
      <Menu selectable={false}>
        <Menu.Item key="/account/settings" >
          <Link to="/account/settings" onClick={() => changeRoute('/account/settings')}>Settings</Link>
        </Menu.Item>
        <Menu.Item key="11" onClick={() => logout()} >
          <span>Sign Out</span>
        </Menu.Item>
      </Menu>
    </div>
  );

  return (
    <Layout className="site-layout">
      {props.sider && <div>
        <Drawer
          placement="left"
          onClose={() => setToggled(true)}
          maskClosable={true}
          visible={!isToggled}
          className="hideOnDesktop"
          
          bodyStyle={{ backgroundColor: "#001529", padding: "0" }}
        >
          <img
            className="logo"
            src={Logo}
            alt="logo"
          />
        <Menu theme="dark" mode="inline" selectedKeys={[selectedKey]}>
          <Menu.Item key="/dashboard" icon={<AppstoreOutlined />} onClick={() => changeRoute('/dashboard')}>
              <span>Dashboard</span>
            </Menu.Item>
            <SubMenu key="account" icon={<UserOutlined />} title="Account">
              <Menu.Item key="/account/qr" onClick={() => changeRoute('/account/qr')}>
                <span >My QR</span>
              </Menu.Item>
              <Menu.Item key="/account/events" onClick={() => changeRoute('/account/events')}>
                <span>Attended Events</span>
              </Menu.Item>
              <Menu.Item key="/account/certificates" onClick={() => changeRoute('/account/certificates')}>
                <span>Certificates</span>
              </Menu.Item>
              {/* <Menu.Item key="/account/cv" onClick={() => changeRoute('/account/cv')}>
                <span>Curriculum Vitae</span>
              </Menu.Item> */}
            </SubMenu>
            <Menu.Item key="/events" icon={<CalendarOutlined />} onClick={() => changeRoute('/events')}>
              <span>Events</span>
            </Menu.Item>
            <Menu.Item key="/recordings" icon={<VideoCameraOutlined />} onClick={() => changeRoute('/recordings')}>
              <span>Recordings</span>
            </Menu.Item>
            <Menu.Item key="/opportunities" icon={<AlertOutlined />} onClick={() => changeRoute('/opportunities')}>
              <span>Opportunities</span>
            </Menu.Item>
            {/* <SubMenu key="career" icon={<BankOutlined />} title="Career">
              <Menu.Item key="/career/agenda" onClick={() => changeRoute('/career/agenda')}>
                <span>Agenda</span>
              </Menu.Item>
              <Menu.Item key="/career/events"onClick={() => changeRoute('/career/events')} >
                <span>Events</span>
              </Menu.Item>
            </SubMenu> */}
          </Menu>
        </Drawer>
        <Sider
          style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            left: 0,
            zIndex: 100
          }}
          collapsible
          breakpoint="lg"
          trigger={null}
          collapsed={isToggled}
          onBreakpoint={(broken) => {
            setToggled(broken);
          }}
          className="hideOnMobile"
        >
          <img
            className="logo"
            src={isToggled ? SquareLogo : Logo }
            alt="logo"
          />
          <Menu theme="dark" mode="inline" selectedKeys={[selectedKey]} defaultOpenKeys={['account']}>
            <Menu.Item key="/dashboard" icon={<AppstoreOutlined />} onClick={() => changeRoute('/dashboard')}>
              <span>Dashboard</span>
            </Menu.Item>
            <SubMenu key="account" icon={<UserOutlined />} title="Account">
              <Menu.Item key="/account/qr" onClick={() => changeRoute('/account/qr')}>
                <span >My QR</span>
              </Menu.Item>
              <Menu.Item key="/account/events" onClick={() => changeRoute('/account/events')}>
                <span>Attended Events</span>
              </Menu.Item>
              <Menu.Item key="/account/certificates" onClick={() => changeRoute('/account/certificates')}>
                <span>Certificates</span>
              </Menu.Item>
              {/* <Menu.Item key="/account/cv" onClick={() => changeRoute('/account/cv')}>
                <span>Curriculum Vitae</span>
              </Menu.Item> */}
            </SubMenu>
            <Menu.Item key="/events" icon={<CalendarOutlined />} onClick={() => changeRoute('/events')}>
              <span>Events</span>
            </Menu.Item>
            <Menu.Item key="/recordings" icon={<VideoCameraOutlined />} onClick={() => changeRoute('/recordings')}>
              <span>Recordings</span>
            </Menu.Item>
            <Menu.Item key="/opportunities" icon={<AlertOutlined />} onClick={() => changeRoute('/opportunities')}>
              <span>Opportunities</span>
            </Menu.Item>
            {/* <SubMenu key="career" icon={<BankOutlined />} title="Career">
              <Menu.Item key="/career/agenda" onClick={() => changeRoute('/career/agenda')}>
                <span>Agenda</span>
              </Menu.Item>
              <Menu.Item key="/career/events"onClick={() => changeRoute('/career/events')} >
                <span>Events</span>
              </Menu.Item>
            </SubMenu> */}
          </Menu>
        </Sider>
      </div>
      }
      <Layout 
        className="site-layout" 
        style={{ 
          overflow: 'auto',
          transition: 'margin-left 0.25s ease ',
          marginLeft: breakpoint.md && props.sider ? isToggled ? 72 : 200 : 0 
        }}>
        <Header className="site-layout-background" style={{ padding: 0 }}>
          {React.createElement(isToggled  ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger',
            onClick: toggleTrueFalse,
          })}
          <span>
            {title}
          </span>
          <Popover 
            content={content} 
            title={`${user ? user.displayName : ''}`} 
            trigger="click" 
            placement="bottomRight" 
            onVisibleChange={() => setMenuToggled(!isMenuToggled)}
            visible={isMenuToggled}>
            <div style={{float: "right", marginRight: 24, cursor: 'pointer'}}>
              <Avatar  size="large" src={undefined} icon={<UserOutlined />} />
            </div>
          </Popover>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: '380px'
          }}
          >
          {props.children}
          <BackToTop></BackToTop>
        </Content>
      </Layout>
    </Layout>
  );
}
