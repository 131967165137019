import React from 'react'
import {Row} from 'antd'
import MyCalendar from '../../components/Calendar'


export default class Agenda extends React.Component {
  render() {
    return (
      <div>
      <Row justify="center" align="middle">
        <MyCalendar/>
      </Row>
    </div>
    )
  }
}