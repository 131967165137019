import React, { useState, useEffect } from 'react'
import Fuse from 'fuse.js'
import Cards from '../components/Cards'
import Loading from '../components/Loading'
import Filter, { FilterChange} from '../components/filter'
import { Empty, BackTop} from 'antd';
import { useHistory } from 'react-router-dom';
import API from '../services/api'
import { Events, Event } from '../types';
import { MdLocationOn, MdEvent, MdLabel } from "react-icons/md";
import {cities} from '../config'
var client = new API()


const fuseOptions = {
  keys: [
    {
      name: 'title',
      weight: 3
    },
    {
      name: 'tags',
      weight: 2
    },
    {
      name: 'location',
      weight: 5
    },
    {
      name: 'description',
      weight: 1
    }
  ]
}

export default function EventsView () {
  const [loading, setLoading] = useState(true);
  const [elements, setElements] = useState<Array<any>>([]);
  const [events, setEvents] = useState<Events>([]);
  const [fuse] = useState<any>(new Fuse([], fuseOptions));
  const [searchActivated, setSearchActivated] = useState<Boolean>(false);
  const history = useHistory();
  const options = cities

  useEffect(() => {
    const fetchMyAPI = async () => {
      let events : Events = await client.getEvents()
      setElements(events)
      setEvents(events)
      fuse.setCollection(events)
      setLoading(false)
    }
    fetchMyAPI()
  }, [])

  const goToEvent = (event : Event) => {
    history.push(`/events/${event.id}`)
  }
  
  const handleChange = (res : FilterChange) => {
    setSearchActivated(true)
    if(res.select == "all" && !res.search) setElements(events)
    else if(res.select || res.search) {
      let query : any = {
        $and: [
        ]
      }
      if(res.select && res.select !== 'all') query.$and.push({location: `'${res.select}`})
      if(res.search) query.$and.push({
        $or: [
          { title: res.search},
          { description: res.search},
          { tags: res.search},
          { location: res.search}
        ]
      })
      let results = fuse.search(query)
      setElements(results.map((x : any) => x.item))
    }
  }

  if (loading) return <Loading/>
  if (!elements.length && !searchActivated) return <Empty  description={'No Events Yet'}/>
  return <div>
    <Filter options={options} handleChange={handleChange} search={true} select={true}></Filter>
    <Cards 
      cards={elements} 
      click={goToEvent} 
      tags={[
        {field: 'city', icon: <MdLocationOn/>, type: 'string'}, 
        {field: 'date', icon: <MdEvent/>, type: 'date'}, 
        {field: 'tags', icon: <MdLabel/>, type: 'array'}
      ]}
    />
  </div>
}
