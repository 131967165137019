import { Internships, Recording, Recordings, Vacancies, Event, Events, Certificates, Certificate, Subscription } from "../types";
import { UserData } from '../components/RegistrationForm'
import {certificateRules, cities} from '../config'
import moment from 'moment'
var cloneDeep = require('lodash.clonedeep');

const axios = require('axios').default;
const baseUrl = "https://capitant.be/"
const url = "https://capitant.be/api"

export default class API {
	async getEvents () : Promise<Events> {
		let res = await axios({
			method: 'post',
			url: `${url}/events.list`,
		});
		let events : Events = res.data.map( (x : any) => {
			return {
				id: x.id || null,
				title: x.name ? x.name : null,
				description: x.description ? x.description : null,
				image: x.visual ? baseUrl + x.visual : null,
				location: x.location ? x.location: null,
				city: x.location ? (cities.some(z => z.value === x.location) ? cities.find(z => z.value == x.location)?.text : null) : null,
				link: x.url ? x.url : null,
				date: x.date ? new Date(x.date) : new Date(),
				type: x.type ? x.type : null,
				tags: x.tags ? x.tags.split(',') : [],
			}
		})
		return events.sort((a: Event, b : Event)=> b.date.getTime() - a.date.getTime());
	}

	async getEvent (id : string) : Promise<Event> {
		let res = await axios({
			method: 'post',
			url: `${url}/events.list`,
			data: {
				filter:{
					ids: [id]
				}
			}
		});
		let x = res.data[0]
		if (!x) x = {}
		let event : Event = {
			id: x.id ? x.id : null,
			title: x.name ? x.name : null,
			description: x.description ? x.description : null,
			image: x.visual ? baseUrl + x.visual : null,
			location: x.location ? x.location: null,
			city: x.location ? cities.find(z => z.value === x.location)?.text : null,
			link: x.url ? x.url : null,
			date: x.date ? new Date(x.date) : new Date(),
			type: x.type ? x.type : null,
			links: x.links ? x.links : [],
			files: x.files ? x.files : [],
			speakers: x.speakers ? x.speakers : [],
			video: x.video ? x.video : null,
			tags: x.tags ? x.tags.split(',') : [],
		}
		return event
	}

	async getUpcomingEvents () : Promise<Events> {
		let events : Events = await this.getEvents()

		return events
			.filter(x => x.date.setHours(0,0,0,0) > (new Date()).setHours(0,0,0,0))
			.sort((a: Event, b : Event)=> b.date.getTime() - a.date.getTime());
	}

	async getVideos () : Promise<Recordings> {
		let res = await axios({
			method: 'post',
			url: `${url}/videos.list`,
		});
		let recordings : Recordings = res.data.map( (x : any) => {
			return {
				id: x.id ? x.id : null,
				title: x.title,
				type: x.type,
				videoId: x.videoId,
				description: x.description,
				published: x.published ? x.published : null,
				tags: x.tags ? x.tags.split(',') : [],
				createdAt: new Date(x.createdAt)
			}
		})
		return recordings.filter(x => x.published == true).sort((a,b)=>b.createdAt.getTime()-a.createdAt.getTime());
	}

	async getVideo (id : string) : Promise<Recording> {
		let res = await axios({
			method: 'post',
			url: `${url}/videos.list`,
			data: {
				filter:{
					ids: [id]
				}
			}
		});
		let x = res.data.find((x : any) => x.id.toString() === id)
		if (!x) x = {}
		let recording : Recording = {
			id: x.id ? x.id : null,
			title: x.title ? x.title : null,
			type: x.type ? x.type : null,
			description: x.description ? x.description : null,
			videoId: x.videoId ? x.videoId : null,
			published: x.published ? x.published : null,
			tags: x.tags ? x.tags.split(',') : [],
			createdAt: new Date(x.createdAt)
		}
		return recording
	}

	async getVacancies() : Promise<Vacancies> {
		let res = await axios({
			method: 'post',
			url: `${url}/vacancies.list`,
		});
		let vacancies : Vacancies = shuffle(res.data.map((x : any) => {
			return {
				title: x.title,
				description: x.description,
				link: x.url,
				image: x.visual ? baseUrl + x.visual : null,
				location: x.location,
				tags: x.tags ? x.tags.split(',') : [],
			}
		}))
		return vacancies
	}

	async getInternships() : Promise<Internships> {
		let res = await axios({
			method: 'post',
			url: `${url}/internships.list`,
		});
		let internships : Internships = shuffle(res.data.map( (x : any) => {
			return {
				title: x.title,
				description: x.description,
				link: x.url,
				image: x.visual ? baseUrl + x.visual : null,
				location: x.location,
				tags: x.tags ? x.tags.split(',') : [],
			}
		}))
		return internships
	}

	async getAttendedEvents () : Promise<Events> {
		let res = await axios({
			method: 'post',
			url: `${url}/attendees.list`,
			data: {
				"userId": "773"
			}
		});
		let data = res.data
		let events : Events = []
		for (let i = 0; i < data.length; i++) {
			let event : Event = await this.getEvent(data[i].eventId)
			events.push(event)
		}
		return events
	}

	async getCertificates () : Promise<Certificates> {
		const checkProgress = (rule : any, events: Events) => {
			let total = rule.requirements.map((x : any) => x.value).reduce((a : any, b : any) => a + b, 0)
			let count = 0
			rule.requirements.forEach((requirement : any) => {
				count+= attendedEvents.filter(x => x.type === requirement.name).length
			})
			return count / total
		}

		const getRequired = (rule : any, events: Events) => {
			rule.requirements.forEach((requirement : any) => {
				requirement.value = requirement.value - events.filter(x => x.type === requirement.name).length
			})
			rule.requirements.forEach((x : any) => x.value < 0 ? x.value = 0 : x.value)
			return rule.requirements
		}

		let attendedEvents : Events = await this.getAttendedEvents()

		let certificates : Certificates = []

	  cloneDeep(certificateRules).forEach((rule : any) => {
			let certificate : Certificate = {
				name: rule.name,
				title: rule.title,
				color: rule.color,
				progress: checkProgress(rule, attendedEvents),
				required: getRequired(rule, attendedEvents)
			}
			certificates.push(certificate)
		})

		return certificates
	}

	requestCertificate (certificate : Certificate) {
		console.log('certificate requested')
		console.log(certificate)
	}

	getCareerAgenda () {

	}
	getCareerEvents () {

	}

	getSubscription (subscription : Subscription) {
		console.log('subscription requested')
		console.log(subscription)
	}

	async getUserData () : Promise<UserData> {
		let userData : UserData = {
			gender: null,
			dateOfBirth: moment('10/02/1999', 'DD/MM/YYYY'),
			city: 'ghent',
			institute: null,
			studies: [],
			year: null,
			yearOfGraduation: null,
		}
		return userData
	}

	saveUserInfo (userInfo : any) {
		console.log('request received')
		console.log(userInfo)
	}
}

function shuffle(a : any) {
    var j, x, i;
    for (i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = a[i];
        a[i] = a[j];
        a[j] = x;
    }
    return a;
}