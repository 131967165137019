import React, { useState, useEffect } from 'react'
import { Layout, Row, Col, Card, Image, Input, Button, Divider, Form, message, Modal } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { createFromIconfontCN } from '@ant-design/icons';
import auth from '../services/auth'
import Loading from '../components/Loading';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_2122088_6ve6suxjoq8.js',
});
const logo = require('../assets/capitant-square-invert.png')
const logo2 = require('../assets/logo_white.png')
const { Header, Content} = Layout;

interface signin {
  email: string
  password: string
}

interface signup {
  email: string
  password: string,
  firstName: string,
  lastName: string
}

export default function Authenticaiton () {
  const [active, setActive] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [requestLoading, setRequestLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const breakpoint = useBreakpoint()
  const social = [
    {text: 'Facebook', icon: 'iconfacebook', color: '#4267B2'},
    {text: 'Google', icon: 'icongoogle', color: '#DB4437'},
    {text: 'Microsoft', icon: 'iconmicrosoft', color: '#00A4EF'},
  ]

  const signInWithSocial = (option : string) => {
    auth.login(option, {email: undefined, password: undefined})
  }

  useEffect(() => {
    const redirect = async () => {
      await auth.redirect()
      setLoading(false)
    }
    redirect()
  }, [])

  const onFinish = async (values : signin) => {
    try {
      // let res = await client.login(values.email, values.password)
      auth.login('Email', {email: values.email, password: values.password})
    } catch (e) {
      message.error(`Email or password are wrong`);
    }
  };

  const onFinishFailed = (errorInfo : any) => {
    console.log('Failed:', errorInfo);
  };

  const onFinishSignUp = async (values : signup) => {
    try {
      // let res = await client.login(values.email, values.password)
      auth.signup({email: values.email, password: values.password}, values.firstName, values.lastName)
    } catch (e) {
      message.error(`Email or password are wrong`);
    }
  };

  const onFinishFailedSignUp = (errorInfo : any) => {
    console.log('Failed:', errorInfo);
  };

  const LoginForm = (props : any) => {
    return <div>
    <h2 className={'text'}>Sign In</h2>
    <Row justify="center" style={{padding: '0px 25px 0px 25px'}}>
      <Form
        name={`signup-${props.deviceType}`}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        style={{width: '100%'}}
      >
        <Form.Item
          name="email"
          rules={[{ required: true, message: 'email Required' }, { type: 'email' }]}
        >
          <Input prefix={<UserOutlined />} placeholder="Email" className='login-text-field'/>
        </Form.Item>
        
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Password required' }]}
        >
          <Input.Password prefix={<LockOutlined />} placeholder="Password" className='login-text-field'/>
        </Form.Item>
        <div style={{textDecoration: 'underline', cursor: 'pointer', paddingBottom: 25, marginTop: -18}} onClick={() => showModal()}>Forgot your password?</div>

        <Form.Item style={{margin: 'auto'}}>
          <Row justify='center'>
            <Button type="primary" htmlType="submit" shape="round" style={{width: 150}}  size={'large'}>
              SIGN IN
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Row>
    <Divider style={{fontWeight: 400, borderTop: 'rgba(135, 135, 135, 0.8)'}} className={'text'}>Or Sign In With</Divider>
    {social.map((socialProvider, i) => {
      return <Row justify="center" style={{padding: 5}} key={i}>
          <Button 
          onClick={() => signInWithSocial(socialProvider.text)}
          type="primary" 
          shape="round" 
          size={'large'} 
          style={{width: 200, backgroundColor: socialProvider.color, color: 'white', borderColor: socialProvider.color}}
    icon={<IconFont style={{fontSize: 'large'}} type={socialProvider.icon} />}>{socialProvider.text}</Button>
        </Row>
    })}
    <Row justify="center" style={{padding: 10}}>
      <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => setActive(2)}>Create an account</span>
    </Row>
  </div>
  }

  const SignupForm = (props: any) => {
    return (
      <div>
        <h2>Sign Up</h2>
        <Row justify="center" style={{padding: '0px 25px 0px 25px'}}>
          <Form
            name={`signup-${props.deviceType}`}
            onFinish={onFinishSignUp}
            onFinishFailed={onFinishFailedSignUp}
            style={{width: '100%'}}
          >
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'email Required' }, { type: 'email' }]}
            >
              <Input prefix={<UserOutlined />} placeholder="Email" className='login-text-field'/>
            </Form.Item>

            <Form.Item
              name="firstName"
              rules={[{ required: true, message: 'name required' }]}
            >
              <Input prefix={<UserOutlined />} placeholder="First name" className='login-text-field'/>
            </Form.Item>

            <Form.Item
              name="lastName"
              rules={[{ required: true, message: 'name required' }]}
            >
              <Input prefix={<UserOutlined />} placeholder="Last name" className='login-text-field'/>
            </Form.Item>

            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Password required' }]}
            >
              <Input.Password prefix={<LockOutlined />} placeholder="Password" className='login-text-field'/>
            </Form.Item>

            <Form.Item
              name="repeatPassword"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject('The two passwords that you entered do not match!');
                  },
                }),
              ]}
            >
               <Input.Password placeholder="Repeat Password" prefix={<LockOutlined />} className='login-text-field'/>
            </Form.Item>

            <Form.Item style={{margin: 'auto'}}>
              <Row justify='center'>
                <Button type="primary" htmlType="submit" shape="round" style={{width: 150}}  size={'large'}>
                  SIGN UP
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </Row>
        <Divider style={{fontWeight: 400, borderTop: 'rgba(135, 135, 135, 0.8)'}} className={'text'}>Or Sign Up With</Divider>
        <Row justify="space-around" className={'social-sign-up'}>
          {social.map((socialProvider, i) => {
            return <Col key={i}>
            <Button
              onClick={() => signInWithSocial(socialProvider.text)}
              type="text"
              icon={<IconFont style={{fontSize: 'xx-large'}}
              type={socialProvider.icon} />}
              style={{color: socialProvider.color}}
              size={'large'}
            />
          </Col>
        })}
        </Row>
        <Row justify="center" style={{padding: 10}}>
          <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => setActive(1)}>Already have an account?</span>
        </Row>
      </div>
    )
  }

  const showModal = () => {
    setVisible(true)
  };

  const handleOk = () => {
    message.success(`Email sent`);
    setVisible(false)
    setRequestLoading(false)
  };

  const handleCancel = () => {
    message.error(`Something went wrong`);
    setRequestLoading(false)
  };

  const onFinishForgotPassword = async (values : any) => {
    try {
      setRequestLoading(true)
      await auth.forgotPassword(values.email)
      handleOk()
    } catch (e) {
      handleCancel()
      message.error(`Email or password are wrong`);
    }
  };

  const onFinishFailedForgotPassword = (errorInfo : any) => {
    console.log('Failed:', errorInfo);
  };

  const ForgotPassword = () => {
    return <Modal
      title="Request New Password"
      visible={visible}
      centered
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <Row justify="center" style={{padding: '0px 25px 0px 25px'}}>
        <Form
          name="forgot-password"
          onFinish={onFinishForgotPassword}
          onFinishFailed={onFinishFailedForgotPassword}
          style={{width: '100%'}}
        >
          <Form.Item
            name='email'
            rules={[{ required: true, message: 'email Required' }, { type: 'email' }]}
          >
            <Input prefix={<UserOutlined />} placeholder="Email" className='login-text-field'/>
          </Form.Item>


          <Form.Item style={{margin: 'auto'}}>
            <Row justify='center'>
              <Button type="primary" htmlType="submit" shape="round"  size={'large'} loading={requestLoading}>
                Request New Password
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </Row>
    </Modal>
  }

  return <div className="login">
    <Header style={{ padding: 0, backgroundColor: 'transparent' }}>
    <Row justify='center'>
      {breakpoint.lg ||
        <img
          className="logo"
          src={logo2}
          alt="logo"
        />
      }
    </Row>
    </Header>
    <Content
      className="site-layout-background auth-layout"
      style={{
        minHeight: 380,
      }}
    >
      {loading ? <Loading description={"You'll be redirected soon..."}/>
      : <div>
        <Col 
          xs={0}
          sm={24}
          style={{        
            position: 'relative',
            paddingTop: 40,
          }}
        >
        <Row justify="center" align="middle" style={{height: '100% -40px'}}>
          <Col span={0} lg={12}>
            <Row justify="center" align="middle">          
              <Image src={logo} width={'50%'} preview={false} style={{maxWidth: 250}}></Image>
            </Row>
          </Col>

          <Col span={24} lg={12} >
            <Row justify="center" align="middle" >          
              <Card
                loading={false}
                className='login-card'
                bordered={false}
                style={{ width: '70%', maxWidth: 400, margin: "auto", marginTop: 5, marginBottom: 5}}
              >
                {active === 1
                ? <LoginForm key={"desktop-login"} deviceType={'desktop'}></LoginForm>
                : <SignupForm key={"desktop-signup"} deviceType={'desktop'}></SignupForm>}
              </Card>
            </Row>
          </Col>
        </Row>
        </Col>
        <Col xs={24} sm={0} style={{paddingTop: 20, color: 'white'}}>
          <Row justify="center" align="middle">          
            {active === 1
              ? <LoginForm key={"mobile-login"} deviceType={'mobile'}></LoginForm>
              : <SignupForm key={"mobile-signup"} deviceType={'mobile'}></SignupForm>}
          </Row>
        </Col>
      </div>
      }
      <ForgotPassword/>
      </Content>
  </div>
}

