import React, { useState} from 'react'
import { Row, Col, Button, Switch, message, Modal, Form, Input} from 'antd'
import {
  LockOutlined,
  RightOutlined,
  UserOutlined
} from '@ant-design/icons';
import auth from '../../services/auth';
import { useHistory } from 'react-router';
import { EventBus } from '../../EventBus';

export default function Settings () {
  const [notifications, setNotifications] = useState(true);
  const [notifications2, setNotifications2] = useState(true);
  const [visible, setVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();

  const showModal = () => {
    setVisible(true)
  };

  const handleOk = () => {
    message.success(`Password changed successfully`);
    setVisible(false)
    setLoading(false)
  };

  const handleCancel = () => {
    message.error(`Something went wrong`);
    setLoading(false)
  };

  interface newPassword {
    currentPassword: string;
    newPassword: string;
  }

  const onFinishNewPassword = async (values : newPassword) => {
    try {
      setLoading(true)
      await auth.changePassword(values.currentPassword, values.newPassword)
      handleOk()
    } catch (e) {
      handleCancel()
    }
  };

  const onFinishFailedNewPassword = (errorInfo : any) => {
    console.log('Failed:', errorInfo);
  };

  const goToUserSettings = () => {
    history.push('/account/settings/user')
    EventBus.$emit('changeTitle')
  }

  const NewPassword = () => {
    return <Modal
      title="Request New Password"
      visible={visible}
      centered
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <Row justify="center" style={{padding: '0px 25px 0px 25px'}}>
        <Form
          name="new-password"
          onFinish={onFinishNewPassword}
          onFinishFailed={onFinishFailedNewPassword}
          style={{width: '100%'}}
        >
          <Form.Item
              name="currentPassword"
              rules={[{ required: true, message: 'Password required' }]}
            >
              <Input.Password prefix={<LockOutlined />} placeholder="Current Password" className='login-text-field'/>
            </Form.Item>
          <Form.Item
            name="newPassword"
            rules={[{ required: true, message: 'Password required' }]}
          >
            <Input.Password prefix={<LockOutlined />} placeholder="New Password" className='login-text-field'/>
          </Form.Item>

          <Form.Item
            name="repeatPassword"
            dependencies={['newPassword']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('The two passwords that you entered do not match!');
                },
              }),
            ]}
          >
              <Input.Password placeholder="Repeat New Password" prefix={<LockOutlined />} className='login-text-field'/>
          </Form.Item>

          <Form.Item style={{margin: 'auto'}}>
            <Row justify='center'>
              <Col>
                <Button type="primary" htmlType="submit" shape="round"  size={'large'} loading={loading}>
                  Request New Password
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Row>
    </Modal>
  }

  function MyButton (props : any) {
    return (
      <Row style={{padding: "5px 0px 5px 0px"}}>
        <Button type="default" shape="round" size={'large'} style={{width: '100%'}} onClick={props.click}>
          <Col style={{float: 'left', paddingRight: 10}}>
            {props.icon}
          </Col>
          <Col style={{float: 'left'}}>
            {props.children}
          </Col>
        </Button>
        <Col style={{position: 'absolute', right: 20, bottom: '26%'}}>
          {props.rightIcon ? props.rightIcon : <RightOutlined style={{fontSize: 18}}/>}
        </Col>
      </Row>
    )
  }

  function MyRow (props: any) {
    return (<Row justify="center" style={{paddingTop: 20}}>
      <Col style={{maxWidth: 600, width: '100%'}}>
        <span style={{padding: "5px 0px 5px 0px"}}>{props.text}</span>
        {props.children}
      </Col>
    </Row>)
  }

  const onChange = (checked : boolean) => {
    setNotifications(!notifications)
  }

  const onChange2 = (checked : boolean) => {
    setNotifications2(!notifications2)
  }

  return (<div>
    <NewPassword/>
    <MyRow text={'Account'}>
      <Col>
        <MyButton click={goToUserSettings} icon={<UserOutlined />}>
          User Info
        </MyButton>
      </Col>
      <Col>
        <MyButton click={showModal} icon={<LockOutlined />}>
          Change Password
        </MyButton>
      </Col>
    </MyRow>
    <MyRow text={'Notifications'}>
      <Col>
      < MyButton 
        icon={<LockOutlined/>} 
        rightIcon={<Switch checked={notifications} onChange={onChange}/>} 
        click={onChange}
      >
        Stay Up To Date
      </MyButton>
      </Col>
      <Col>
        <MyButton 
          icon={<LockOutlined/>} 
          rightIcon={<Switch checked={notifications2} onChange={onChange2}/>} 
          click={onChange2}
        >
          Partner Updates
        </MyButton>
      </Col>
    </MyRow>
    <Row justify={'center'} style={{padding: 30}}>
      <Button type="primary" shape="round" size={'large'} onClick={() => auth.signOut()}>
        Sign Out
      </Button>
    </Row>
  </div>)
}
