import cookies from 'js-cookie'
export default {
    setCookie (location: string, content : string) {
        cookies.set(location, JSON.stringify(content), { expires: 7 })
    },
    getCookie (location : string) {
        let cookie = cookies.get(location)
        if (cookie) return JSON.parse(cookie)
        return null 
    },
    removeCookie (location : string) {
        cookies.remove(location)
    }
}