import React, { useEffect, useState } from 'react'
import {Row, Col, Card, Button} from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { Events, Subscriptions, Subscription } from '../types';
import Layout from '../components/MyLayout'
import API from '../services/api'
var client = new API()

var subscriptions : Subscriptions = [
  {name: "1 Year", price: 5, description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit', topPick: false},
  {name: "2 Years", price: 9, description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit', topPick: true},
  {name: "3 Years", price: 13, description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit', topPick: false}
]

export default function Memberships () {
  const [loading, setLoading] = useState<boolean>(true)
  const [events, setEvents] = useState<Events>([])

  const breakpoint = useBreakpoint()

  useEffect(() => {
    const fetchMyAPI = async () => {
      setLoading(false)
    }
    fetchMyAPI()
  }, [])

  return (<div>
    <Layout sider={false}>
      <Row justify={'center'}>
        <h2>Become A Member</h2>
      </Row>
      <Row gutter={16} align={'middle'} style={{maxWidth: 1100, margin: 'auto'}}>
        {subscriptions.map((subscription : Subscription, i : any) => {
          return (
            <Col key={i} span={24} md={8} style={{paddingTop: 20, maxWidth: 400, margin: 'auto'}}>
              <div style={{borderRadius: 15, boxShadow: subscription.topPick ? "5px 8px 24px 5px rgba(201, 217, 242, 0.55)" : "5px 8px 24px 5px rgba(201, 217, 242, 0.4)" }}>
                <Card style={{borderRadius: 15, cursor: 'auto'}} hoverable >
                  <div style={{paddingTop: subscription.topPick && 25, paddingBottom: subscription.topPick && 25}}>
                    {subscription.topPick &&
                      <div 
                        style={{
                          position: 'absolute', 
                          top: 0, 
                          left: 0,
                          width: '100%', 
                          backgroundColor: '#6a7485', 
                          color: 'white',
                          borderTopLeftRadius: 15,
                          borderTopRightRadius: 15
                          }}>
                        <Row justify={'center'} align={'middle'}>
                          <span style={{padding: 4, fontWeight: 500, fontSize: 18}}>Best Value</span>
                        </Row>
                      </div>
                    }
                    <Row justify={'center'}>
                      <h1 style={{fontSize: 26}}>
                        {subscription.name}
                      </h1>
                    </Row>
                    <Row justify={"center"} style={{textAlign: 'center', paddingBottom: 20}}>
                      {subscription.description}
                    </Row>
                    <Row justify={"center"}>
                      <h1>
                        <span style={{fontSize: 22, paddingRight: 5}}>
                          {'€'}
                        </span>
                        <span style={{fontSize: 32}}>
                          {subscription.price}
                        </span>
                      </h1>
                    </Row>
                    <Row justify={"center"} style={{paddingTop: 20}}>
                      <Button 
                        type={subscription.topPick ? 'primary' : 'default'} 
                        shape="round" style={{width: 120}}  
                        size={'large'}
                        onClick={() => client.getSubscription(subscription)}>
                        Select</Button>
                    </Row>
                  </div>
                </Card>
              </div>
            </Col>
          )
        })}
      </Row>
    </Layout>
  </div>
  )
}

