import React from "react";
import { Spin, Col, Row } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
export default function Loading (props : any) {  
	return (
		<Row justify="center" align="middle" style={{height: 'inherit'}}>
			<Col>	
				<Row justify="center">
					<Spin indicator={<LoadingOutlined style={{ fontSize: 36}} />}/>
				</Row>
				<Row>
					<h2 style={{color: 'white', fontWeight: 600,}}>{props.description}</h2>
				</Row>
			</Col>
		</Row>
	)
}


  
