import * as React from 'react'
import { Row, Col, Card, Carousel} from 'antd'
import DynamicCardContent from '../DynamicCardContent'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'

interface Props {
    loading: boolean
}

export default function CertificateProgress (props: Props) {
	const breakpoint = useBreakpoint()
  return (
	<Row justify="center" style={{height: "100%"}}>
		<Col span={24}>
			<Row style={{height: "100%"}}>
				<Card title={'Meet The Speakers'} style={{ width: "100%", height:"100%"}} loading={props.loading}>
					<div>
						<Carousel autoplay dotPosition={breakpoint.md ? 'right' : 'bottom'}>
							<div>
								<DynamicCardContent 
									title={'Lucas Stoops'} 
									description={'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam '}
									imagePlaceholder={'https://static.capitant.gent/public/2020/etwof/lucas-stoops.jpg'}
									roundImage={true}
								/>
							</div>
							<div>
								<DynamicCardContent 
									title={'Pieter-Jan Van Haute'} 
									description={'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam '}
									imagePlaceholder={'https://static.capitant.gent/public/2020/etwof/pieter-jan-van-haute.jpg'}
									roundImage={true}
								/>
							</div>
							<div>
								<DynamicCardContent 
									title={'Thomas Batsleer'} 
									description={'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam '}
									imagePlaceholder={'https://static.capitant.gent/public/2020/etwof/thomas-batsleer.jpg'}
									roundImage={true}
								/>
							</div>
						</Carousel>
					</div>
				</Card>
			</Row>
		</Col>
	</Row>
	)
}