import * as React from 'react'
import { Row, Col, Card } from 'antd'
import {useHistory} from 'react-router-dom'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import DynamicCardContent from '../DynamicCardContent'


interface Props {
	widgetTitle: string,
	title: string,
	description: string,
	loading: boolean
}

export default function OffTheMonth (props: Props) {
  return (
		<Row justify="center" style={{height: "100%"}}>
			<Col span={24}>
				<Row style={{height: "100%"}}>
					<Card title={props.widgetTitle} style={{ width: "100%", height:"100%"}} loading={props.loading}>
						<DynamicCardContent title={props.title} description={props.description}/>
					</Card>
				</Row>
			</Col>
		</Row>
	)
}