import * as React from 'react'
import { Row, Col, Card, List, Empty} from 'antd'
import {useHistory} from 'react-router-dom'

interface Item {
  text: string
  id: string | number | null
  link?: string | null
}

interface Props {
  items: Array<Item>
  empty?: string
  maxHeight?: number,
  onClick?: any
}

const defaultProps = {
  maxHeight: 220,
  empty: 'No Items Available',
  items: [],
  onClick: () => null
}

export default function ScrollableList (props: Props) {
  props = {...defaultProps, ...props}
	const history = useHistory()
	const back = () => {
		history.push('/')
  }
  if(!props.items.length) return <Empty style={{margin: 'auto'}} description={props.empty}/>
  return (
		<div className={'scrollable-list'}>
      <List
        style={{overflow: "auto", width: "100%", maxHeight: props.maxHeight}}
        size={'small'}
        dataSource={props.items}
        renderItem={item => (
          <List.Item onClick={() => props.onClick(item)}>
            {item.text}
          </List.Item>
        )}
      />
		</div>
	)
}