import React, { useState } from "react";
import moment from 'moment'
import {  Form, message, Select, DatePicker, Cascader, Button, Row } from 'antd';
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import {cities, studiesOptions} from '../config'
import {Genders} from '../enums'

export interface UserData {
  gender: Genders | null
  dateOfBirth: moment.Moment | null
  city: string | null
  institute: string | null
  studies: Array<string>
  year: number | null
  yearOfGraduation: string | null
}

interface Props {
  save: any,
  userData?: UserData
}

export default function RegistrationForm (props: Props) {
  const [selectedDegree, setSelectedDegree] = useState<string | null>()
  const breakpoint = useBreakpoint();
  const onFinish = async (values : UserData) => {
      try {
        props.save(values)
      } catch (e) {
        message.error(`Email or password are wrong`);
      }
    };
  
    const onFinishFailed = (errorInfo : any) => {
      console.log('Failed:', errorInfo);
    };

    const onChange = (value : any) => {
      if(!value) setSelectedDegree(null);
      setSelectedDegree(value[0])
      console.log(value);
    };
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
        md: { span: 4 },
        lg: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 19 },
        lg: { span: 18 },
      },
    };
	return (
    <Form
      {...formItemLayout}
      name="basic"
      layout="horizontal"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      style={{width:"100%"}}
      initialValues={props.userData ? props.userData : {}}
    >
      <Form.Item 
      name="gender"
      label="Gender">
        <Select placeholder={'Select gender'}>
          <Select.Option value={Genders.male}>Male</Select.Option>
          <Select.Option value={Genders.female}>Female</Select.Option>
          <Select.Option value={Genders.undefined}>I rather not say</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item 
      name="dateOfBirth"
      label="Date of birth">
        <DatePicker 
          format={"DD/MM/YYYY"} 
          showToday={false} 
          style={{width:"100%"}} 
          placeholder={'Select date of birth'}
          defaultPickerValue={props.userData && props.userData.dateOfBirth ? props.userData.dateOfBirth  : moment().subtract(19, 'years')}
        />
      </Form.Item>
      <Form.Item 
      name="city"
      label="City">
        <Select placeholder={'Select city'}>
          {cities.filter(x=>!x.optional).map((city :any) => <Select.Option value={city.value} key={city.value}>{city.text}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item 
      name="institute"
      label="Institute">
        <Select placeholder={'Select institute'}>
          <Select.Option value="Ghent University">Ghent University</Select.Option>
          <Select.Option value="Arteveldehogeschool">Arteveldehogeschool</Select.Option>
          <Select.Option value="none">None of the above</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item 
      name="studies"
      label="Studies">
        <Cascader options={studiesOptions} onChange={onChange} placeholder={'Select studies'}/>
      </Form.Item>
      <Form.Item 
      name="year"
      label="Year">
        <Select placeholder={'Select Year'}>
          {[1,2,3].map((x : number) => {
            if(selectedDegree === "Master" && x === 3)
              return null;
            return <Select.Option value={x} key={x}>{x}</Select.Option>
          })}
        </Select>
      </Form.Item>
      <Form.Item 
        name="yearOfGraduation"
        label="Graduation">
        <Select placeholder={'Select expected year of graduation'}>
          {[0, 1, 2,3,4].map((x : number) => {
            let output = `${new Date().getFullYear()+ x}-${new Date().getFullYear()+x+1}`
            return <Select.Option key={x} value={output}>{output}</Select.Option>
          })}
        </Select>
      </Form.Item>
      <Row justify='center' style={{width:'100%', paddingBottom: 30, paddingTop: 15}}>
        <Button type="primary" htmlType="submit" shape="round" style={{width: 150}}  size={'large'}>
          Save
        </Button>
      </Row>
    </Form>
	)
}

