import React, {useState} from 'react'
import { Row, Col, Card } from 'antd'
import {useHistory} from 'react-router-dom'
import Text from '../Text'

interface Props {
	loading: boolean,
	title: string
	description: string
}

export default function Description (props: Props) {
  return (
		<Row justify="center" style={{height: "100%"}}>
			<Col span={24}>
				<Row style={{height: "100%"}}>
						<Card 
							title={props.title} 
							style={{ width: "100%", height:"100%"}} 
							loading={props.loading}
						>
							<Text description={props.description} maxLength={650}/>
						</Card>
				</Row>
			</Col>
	</Row>
	)
}

