import React from 'react'
import { Form, Button, Row, Upload, message, Checkbox } from 'antd'
import { InboxOutlined } from '@ant-design/icons';
const { Dragger } = Upload;

export default function Event () {

  const onFinish = async (values : any) => {
		console.log(values)
  };

  const onFinishFailed = (errorInfo : any) => {
    console.log('Failed:', errorInfo);
	};
	
	const props = {
		name: 'file',
		multiple: true,
		action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
		onChange(info : any) {
			const { status } = info.file;
			if (status !== 'uploading') {
				console.log(info.file, info.fileList);
			}
			if (status === 'done') {
				message.success(`${info.file.name} file uploaded successfully.`);
			} else if (status === 'error') {
				message.error(`${info.file.name} file upload failed.`);
			}
		},
	};

	return <div>
		<Row justify="center" style={{padding: 50}}>
			<Form
				name="basic"
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				style={{width: '100%', maxWidth: 450}}
			>
				<Form.Item
					name="files"
					valuePropName="fileList"
				>

					<Dragger {...props} >
						<p className="ant-upload-drag-icon">
							<InboxOutlined />
						</p>
						<p className="ant-upload-text">Click or drag file to this area to upload</p>
						<p className="ant-upload-hint">
							Lorem Ipsum
						</p>
					</Dragger>
				</Form.Item>
				<Form.Item
					name="allowThirdParties"
					valuePropName="checked"
				>
					<Checkbox defaultChecked={false}>I want to share my CV with third parties</Checkbox>
				</Form.Item>

				<Form.Item style={{margin: 'auto'}}>
					<Row justify='center'>
						<Button type="primary" htmlType="submit" shape="round" style={{width: 150}}  size={'large'}>
							SUBMIT
						</Button>
					</Row>
				</Form.Item>
			</Form>
		</Row>
	</div>
}

