import React, { useEffect, useState } from "react";
import { Row, Select, Grid, Input } from 'antd'
import {
  SearchOutlined
} from '@ant-design/icons';
const { Option } = Select
const { useBreakpoint } = Grid;
interface props {
	options?: Array<any>
	handleChange: any
	search?: boolean
	select?: boolean
}

export interface FilterChange {
	select?: string
	search?: string
}

const defaultProps = {
	options: [],
	search: false,
	select: false
}

export default function Filter (props : props) {
	props = {...defaultProps, ...props}
	const breakpoint = useBreakpoint()
	const [select, setSelect] = useState<string | null>()
	const [search, setSearch] = useState<string | null>()

	useEffect(() => {
			props.handleChange({
				search: search ? search : '',
				select: select
			})
  }, [select, search]);

	return ( 
		<Row justify={breakpoint.md ? "end" : "center"} style={{paddingBottom: 20, paddingRight: 30, paddingLeft: 30}}>
			{props.search &&
			<Input 
				onChange={(e : any) => {
					setSearch(e.target.value);
				}}
				placeholder="Search" 
				style={{ width: 220, margin: 10 }} 
				prefix={<SearchOutlined />} 
			/>
			}
			{props.select && props.options &&
			<Select
			onChange={(value : any) => {
				setSelect(value);
			}}
			defaultValue="all" 
			style={{ width: 180, margin: 10 }} 
			>
				{props.options.map((x : any, i : number) => {
					return <Option key={i} value={x.value}>{x.text}</Option>
				})}
			</Select>
			}
		</Row>
	)
}