
import {Routes} from './types'
import Dashboard from './views/dashboard'
import Settings from './views/account/settings'
import UserInfo from './views/account/userInfo'
import MyQr from './views/account/myQr'
import AttendedEvents from './views/account/attendedEvents'
import Certificates from './views/account/certificates'
import Cv from './views/account/cv'
import Events from './views/events'
import Event from './views/event'
import RecordingsView from './views/recordings'
import RecordingView from './views/recording'
import Agenda from './views/career/agenda'
import CareerEvents from './views/career/events'
import Opportunities from './views/opportunities'
import Authentication from './views/signin'
import NotFound from './components/NotFound'
import Memberships from './views/memberships'

var routes : Routes =  [
  {
    path: "/login",
    title: 'Authentication',
    component: Authentication,
    exact: true,
    requiresAuth: false,
    layout: false,
    alwaysAvailable: true
  },
  {
    path: "/memberships",
    title: 'Memberships',
    component: Memberships,
    exact: true,
    requiresAuth: true,
    layout: false,
    alwaysAvailable: true
  },
  {
    path: "/",
    title: 'Dashboard',
    component: Dashboard,
    exact: true,
    requiresAuth: true,
    layout: true,
    alwaysAvailable: false
  },
  {
    path: "/dashboard",
    title: 'Dashboard',
    component: Dashboard,
    exact: true,
    requiresAuth: true,
    layout: true,
    alwaysAvailable: false
  },
  {
    path: "/account/settings",
    title: 'Settings',
    component: Settings,
    exact: true,
    requiresAuth: true,
    layout: true,
    alwaysAvailable: true
  },
  {
    path: "/account/settings/user",
    title: 'User Settings',
    component: UserInfo,
    exact: true,
    requiresAuth: true,
    layout: true,
    alwaysAvailable: true
  },
  {
    path: "/account/qr",
    title: 'My QR',
    component: MyQr,
    exact: true,
    requiresAuth: true,
    layout: true,
    alwaysAvailable: false
  },
  {
    path: "/account/events",
    title: 'Attended Events',
    component: AttendedEvents,
    exact: true,
    requiresAuth: true,
    layout: true,
    alwaysAvailable: false
  },
  {
    path: "/account/certificates",
    title: 'Certificates',
    component: Certificates,
    exact: true,
    requiresAuth: true,
    layout: true,
    alwaysAvailable: false
  },
  {
    path: "/account/cv",
    title: 'Curriculum Vitae',
    component: Cv,
    exact: true,
    requiresAuth: true,
    layout: true,
    alwaysAvailable: false
  },
  {
    path: "/events",
    title: 'Events',
    component: Events,
    exact: true,
    requiresAuth: true,
    layout: true,
    alwaysAvailable: false
  },
  {
    path: "/events/:id",
    title: 'Event',
    component: Event,
    exact: true,
    requiresAuth: true,
    layout: true,
    alwaysAvailable: false
  },
  {
    path: "/recordings",
    title: 'Recordings',
    component: RecordingsView,
    exact: true,
    requiresAuth: true,
    layout: true,
    alwaysAvailable: false
  },
  {
    path: "/recordings/:id",
    title: 'Recording',
    component: RecordingView,
    exact: true,
    requiresAuth: true,
    layout: true,
    alwaysAvailable: false
  },
  {
    path: "/opportunities",
    title: 'Opportunities',
    component: Opportunities,
    exact: true,
    requiresAuth: true,
    layout: true,
    alwaysAvailable: false
  },
  {
    path: "/career/agenda",
    title: 'Agenda',
    component: Agenda,
    exact: true,
    requiresAuth: true,
    layout: true,
    alwaysAvailable: false
  },
  {
    path: "/career/events",
    title: 'Events',
    component: CareerEvents,
    exact: true,
    requiresAuth: true,
    layout: true,
    alwaysAvailable: false
  },
  {
    path: "*",
    title: 'Not Found',
    component: NotFound,
    exact: true,
    requiresAuth: true,
    layout: true,
    alwaysAvailable: false
  },
]

export default routes