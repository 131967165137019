import React, { useState, useEffect } from 'react'
import {Row, Button, message} from 'antd'
import QrReader from '../../components/qrReader'
import cookies from '../../services/cookies'
import Loading from '../../components/Loading'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
var QRCode = require('qrcode.react')

interface State {
  isLoading: boolean;
  active: number
}

export default function MyQr () {
  const user = cookies.getCookie('user')
  const breakpoint = useBreakpoint()
  const [loading, setLoading] = useState<boolean>(true)
  const [active, setActive] = useState<number>(1)
  const changeOption = (active : number) => {
    setActive(active)
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 200)
  }
  
  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 500);
  }, [])

  const scan = (result : string) => {
    console.log(result)
    setLoading(true)
    setTimeout(() => {
      message.success('scanned')
      setLoading(false)
    }, 2000);
  }

  if (loading) return <Loading/>
  return <div>
    <Row justify="center">
      {!breakpoint.lg &&
        <Row>
          <div style={{marginRight: 10}}>
            <Button type={active === 1 ? "primary" : "default"} onClick={() => changeOption(1)} style={{width: 100}}>QR</Button>
          </div>
          <div style={{marginLeft: 10}}>
            <Button type={active === 2 ? "primary" : "default"} onClick={() => changeOption(2)} style={{width: 100}}>Camera</Button>
          </div >
        </Row>
      }
    </Row>
    <Row justify="center" align="middle" style={{marginTop: "125px"}}>
      {active === 1
      ? <QRCode value={user ? user.claims.user_id : null} size={200}/>
      : <div style={{width: 300}}><QrReader scan={scan} /></div>}
    </Row>
  </div>
}