import React, { useState, useEffect } from 'react'
import Cards from '../../components/Cards'
import Loading from '../../components/Loading'
import { Empty } from 'antd'

export default function CareerEvents () {
  const [isLoading, setIsLoading] = useState(true);
  const [elements, setElements] = useState<Array<any>>([]);

  useEffect(() => {
    const fetchMyAPI = async () => {
      setElements([
        {
          image: "https://picsum.photos/200",
          title: "PWC at the office",
          description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
        },
        {
          image: "https://picsum.photos/200",
          title: "Workshop corporate finance",
          description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
        },
      ])
      setIsLoading(false)
    }
    fetchMyAPI()
  }, [])

  const goToEvent = (item : any) => {
    console.log(item)
  }

  if (isLoading) return <Loading/>
  if (!elements.length) return <Empty  description={'No Events Yet'}/>
  return <Cards cards={elements} click={goToEvent}></Cards>
}