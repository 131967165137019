import React from 'react'
import { Row, Col, Card} from 'antd'
import {useHistory} from 'react-router-dom'
import ScrollableList from '../ScrollableList'

interface Props {
		loading: boolean
		items: Array<any>
}

export default function Files (props: Props) {
  return (
		<Row justify="center" style={{height: "100%"}}>
			<Col span={24}>
				<Row style={{height: "100%"}}>
					<Card title="Files" style={{ width: "100%"}} loading={props.loading}>
						<ScrollableList items={props.items} empty={'No Files Available'} maxHeight={150}/>
					</Card>
				</Row>
			</Col>
		</Row>
	)
}