import React, { useState } from 'react'
import QrReader from 'react-qr-reader'
import {Row} from 'antd'
import Loading from './Loading';

interface State {
    result: string
    loading: boolean
}

interface Props {
  scan: any
}

export default function MyQr (props : Props) {
  const [loading, setLoading] = useState<boolean>(true)


  const cameraReady = () => {
    setLoading(false)
  }

  const handleScan = (data : any) => {
    if (data) {
      props.scan(data)
    }
  }

  const handleError = (err : any) => {
    console.error(err)
  }

  return <div>
    {loading &&
      <Row justify='center' align='middle'>
        <Loading/>
      </Row>
    }
    <QrReader
      delay={300}
      onError={handleError}
      onScan={handleScan}
      onLoad={cameraReady}
      showViewFinder={false}
      style={{ width: '100%' }}
    />
  </div>
  
}
