import React, { useState, useEffect } from 'react'
import { Row, Col, Progress, Button, Divider } from 'antd'
import { TrophyFilled } from '@ant-design/icons';
import Loading from '../../components/Loading'
import API from '../../services/api'
import { DownloadOutlined } from '@ant-design/icons';
import { Certificates } from '../../types';
var client = new API()

export default function CertificatesView () {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [elements, setElements] = useState<Certificates>([])
  useEffect(() => {
    const fetchMyAPI = async () => {
			let certificates : Certificates = await client.getCertificates()
			setElements(certificates)
      setIsLoading(false)
    }
    fetchMyAPI()
  }, [])

	const Trophy = (props : any) => {
		return <Row justify="space-between" align="middle" style={{width: '100%', maxWidth: 800, padding: 10, paddingTop: 30}}>
			<Col span={24} md={0}>
				<Row justify={"center"}>
					<TrophyFilled style={{fontSize: 50, padding: 10, color: props.color}}/>
				</Row>
			</Col>
			<Col span={24} md={10}>
				<div style={{paddingRight: 20}}>
					<div style={{fontWeight: 500, fontSize: 20}}>{props.title}</div>
					<Col xs={0} md={24}>
						<div style={{fontWeight: 500, fontSize: 14}}>{`Still Required`}</div>
						{props.required.map((requirement : any, i : any) => {
								return <div key={i}>{`${requirement.name}: ${requirement.value}`}</div>
							}
						)}
					</Col>
				</div>
			</Col>
			<Col span={24} md={10}>
				<Progress
					strokeColor={{
						from: '#108ee9',
						to: '#87d068',
					}}
					strokeWidth={20}
					percent={round(props.progress * 100, 1)}
					status="active"
				/>
			</Col>
			<Col span={24} xs={24} md={0} style={{padding: 10}}>
			<div style={{fontWeight: 500, fontSize: 14}}>{`Still Required`}</div>
					{props.required.map((requirement : any, i : any) => {
								return <div key={i}>{`${requirement.name}: ${requirement.value}`}</div>
							}
						)}
			</Col>
			<Col span={0} md={4}>
				<Row justify={"end"}>
					<TrophyFilled style={{fontSize: 50, padding: 10, color: props.color}}/>
				</Row>
			</Col>
		</Row>
	}

	if (isLoading) return <Loading/>
	return <Row justify="center">
			{elements.map((trophy, i) => {
				return <Col span={24}  key={i}>
					<Row justify="center">
						<Trophy color={trophy.color} title={trophy.title} name={trophy.name} progress={trophy.progress} required={trophy.required}/>
					</Row>
					{
						trophy.progress === 1 &&
						<Row justify={"center"} style={{padding: 15}}>
							<Button shape="round" icon={<DownloadOutlined /> } type={"default"} size={"large"} onClick={() => client.requestCertificate(trophy)}>
								Request Certificate
							</Button>
						</Row>
					}
					<div style={{maxWidth: 900, margin:"auto"}}>
						<Divider />
					</div>
				</Col>
			})}
	</Row>
}

function round(value : number, precision : number) {
	var multiplier = Math.pow(10, precision || 0);
	return Math.round(value * multiplier) / multiplier;
}