import React, {useState} from 'react'
import {shorten} from '../utils'

interface TextProps {
	description: string
	maxLength: number
}

export default function Text (props : TextProps) {
	const [length, setLength] = useState<number>(props.maxLength);
	if(!props.description) return null
	return (
		<div className={'ant-card-meta-description'}>
			{shorten(props.description, length)}
			{props.description.length > props.maxLength	
				? length !== 100000
					? <strong><a onClick={() => setLength(100000)}> more</a></strong>
					: <strong><a onClick={() => setLength(props.maxLength)}> less</a></strong>
				: null
			}			
		</div>
	)
}
