class _EventBus {
    bus : any
    constructor() {
        this.bus = {};
    }

    $off(id : any) {
       delete this.bus[id];
    }

    $on(id : any, callback : any) {
        this.bus[id] = callback;
    }

    $emit(id : any, ...params : any) {
        if(this.bus[id])
            this.bus[id](...params);
    }
}

export const EventBus = new _EventBus();