import React, { useEffect, useState } from 'react'
import {Row, Col} from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import UpcomingEvents from '../components/dashboard/UpcomingEvents';
import CertificateProgress from '../components/dashboard/CertificateProgress'
import OffTheMonth from '../components/dashboard/OffTheMonth';
import TradingViewWidget from '../components/dashboard/TradingViewWidget'
import { Events } from '../types';
import API from '../services/api'
var client = new API()

export default function Dashboard () {
  const [loading, setLoading] = useState<boolean>(true)
  const [events, setEvents] = useState<Events>([])

  const breakpoint = useBreakpoint()

  useEffect(() => {
    const fetchMyAPI = async () => {
      let events : Events = await client.getUpcomingEvents()
      setEvents(events)
      setLoading(false)
    }
    fetchMyAPI()
  }, [])

  return (<div>
    <Row gutter={20} >
      <Col span={24} md={16}>
        <Row className={'dashboard-row'}>
          <Col span={24}>
            <div style={{height: 160, padding: 20, borderRadius: 15}} className={'welcome-back-widget'}>
              <Row>
                <Col>
                  <h2 style={{color: 'white', lineHeight: 0.35}}>Welcome back!</h2>
                  <h3 style={{color: 'white'}}>Nice to see you again.</h3>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row className={'dashboard-row'}>
          <Col span={24}>
            <div style={{height: 160, }}>
              <CertificateProgress loading={true}/>
            </div>
          </Col>
        </Row>
      </Col>
      <Col span={24} md={8}>
        <Row className={'dashboard-row'} style={{height: "100%"}}>
          <Col span={24}>
            <div style={{height: "100%"}}>
              <UpcomingEvents events={events} loading={loading}/>
              {/* <TradingViewWidget/> */}
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
    <Row gutter={20}>
      <Col span={24} md={12}>
        <Row className={'dashboard-row'}>
          <Col span={24}>
            <div style={{height: "100%", minHeight: 220}}>
              <OffTheMonth loading={loading} widgetTitle={'Book Of The Month'} title={'How To Win Friends And Influence People'} description={'this is a description'}/>
            </div>
          </Col>
        </Row>
      </Col>
      <Col span={24} md={12}>
        <Row className={'dashboard-row'}>
          <Col span={24}>
            <div style={{height: "100%", minHeight: 220}}>
              <OffTheMonth loading={true} widgetTitle={'Article Of The Week'} title={'Article'} description={'this is a description'}/>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
    <Row gutter={20}>
      <Col span={24} md={12}>
        <Row className={'dashboard-row'}>
          <Col span={24}>
            <div style={{ }}>
              <CertificateProgress loading={true}/>
            </div>
          </Col>
        </Row>
      </Col>
      <Col span={24} md={12}>
        <Row className={'dashboard-row'}>
          <Col span={24}>
            <div style={{height: 160, }}>
              <CertificateProgress loading={true}/>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  </div>
  )
}

