import * as React from 'react'
import Fuse from 'fuse.js'
import Cards from '../components/Cards'
import { Button, Row } from 'antd'
import Loading from '../components/Loading'
import { Empty } from 'antd';
import API from '../services/api'
import Filter, {FilterChange} from '../components/filter'
import { Internships, Vacancies } from '../types'
import { MdLabel, MdLocationOn } from 'react-icons/md'
var client = new API()

interface State {
  isLoading: boolean;
  elements: Array<any>;
  internships: Internships;
  vacancies: Vacancies;
  active: number;
  fuse: any;
  search: string;
  searchActivated: boolean
}

const fuseOptions = {
  keys: [
    {
      name: 'title',
      weight: 3
    },
    {
      name: 'location',
      weight: 4
    },
    {
      name: 'description',
      weight: 1
    },
    {
      name: 'tags',
      weight: 3
    }
  ]
}

export default class Opportunities extends React.Component<State> {
  state: State = {
    isLoading: true,
    elements: [],
    internships: [],
    vacancies: [],
    active: 1,
    fuse: new Fuse([], fuseOptions),
    search: "",
    searchActivated: false
  };

  async componentDidMount() {
    let internships : Internships = await client.getInternships()
    let vacancies : Vacancies = await client.getVacancies()
    this.setState({elements: internships, internships, vacancies})
    this.state.fuse.setCollection(internships)
    this.setState({isLoading: false})
    this.state.fuse.search(this.state.search)
  }

  changeOption (active : number) {
    this.setState({active, isLoading: true})
    if(active === 1) {
      this.setState({elements: this.state.internships})
      this.state.fuse.setCollection(this.state.internships)
      this.doSearch({search: this.state.search})
    }
    if(active === 2) {
      this.setState({elements: this.state.vacancies})
      this.state.fuse.setCollection(this.state.vacancies)
      this.doSearch({search: this.state.search})
    }
    setTimeout(() => {
      this.setState({isLoading: false})
    }, 200)
  }

  doSearch = (res : FilterChange) => {
    this.setState({searchActivated: true})
    if(!res.search) this.setState({elements: this.state.fuse._docs})
    else {
      let query : any = {
        $and: []
      }
      if(res.search) query.$and.push({
        $or: [
          { title: res.search},
          { description: res.search},
          { tags: res.search},
          { location: res.search}
        ]
      })
      let results = this.state.fuse.search(query)

      this.setState({elements: results.map((x : any) => x.item)})
    }
  }

  render(){
    return (<div>
      <Filter handleChange={this.doSearch} search={true} select={false}></Filter>

      <Row justify="center" style={{paddingBottom: 20}}>
        <div style={{marginRight: 10}}>
          <Button type={this.state.active === 1 ? "primary" : "default"} onClick={() => this.changeOption(1)} style={{width: 100}}>Internships</Button>
        </div>
        <div style={{marginLeft: 10}}>
          <Button type={this.state.active === 2 ? "primary" : "default"} onClick={() => this.changeOption(2)} style={{width: 100}}>Vacancies</Button>
        </div>
      </Row>

      {this.state.isLoading 
      ? <Loading/> 
      : !this.state.elements.length 
        ? <Empty  description={'No Events Yet'}/> 
        : <Cards 
            cards={this.state.elements.map((x : any) => ({...x, description: x.description }))} 
            click={goToDocument}
            tags={[{field: 'location', icon: <MdLocationOn/>, type: 'string'},{field: 'tags', icon: <MdLabel/>, type: 'array'}]}
          />
      }
    </div>
    )
  }
}

function goToDocument (item : any) {
  window.open(item.link, '_blank');
}