import * as React from 'react'
import { Row, Col, Card} from 'antd'
import {useHistory} from 'react-router-dom'
import { Events } from '../../types';
import ScrollableList from '../ScrollableList'
interface Props {
	loading: boolean
	events: Events
}

export default function UpcomingEvents (props: Props) {

	const goToFacebook = (event : any ) => {
		console.log(event)
		window.open(event.link, '_blank')
	}
  return (
		<Row justify="center" style={{height: "100%"}}>
			<Col span={24}>
				<Row style={{height: "100%"}}>
						<Card  title="Upcoming Events" style={{ width: "100%"}} loading={props.loading}>
							<Row style={{height: 220}}>
								<ScrollableList 
									items={props.events.map(x => {return {text: x.title, id: x.id, link:x.link}})} 
									empty={'No Events Available'}
									onClick={goToFacebook}
								/>
							</Row>
						</Card>
				</Row>
			</Col>
		</Row>
	)
}