import React, { useEffect } from 'react';
import './App.css';
import {Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom'
import MyLayout from './components/MyLayout'
import routes from './routes'
import cookies from './services/cookies'
import auth from './services/auth';
import {Route as IRoute} from './types'

function App() {
  const user : any = cookies.getCookie('user')
  console.log(user)
  useEffect(() => {
    auth.onAuthStateChanged()
  }, [])

  return (
    <div className="App">
      <Router>
        <Switch>
          {/* routes without layout */}
          {routes.filter(x => !x.layout).map((route : IRoute, i : number) => {
            return (<RouteWithSubRoutes  key={i}{...route} />)
          })}
          <MyLayout>
            <Switch>
              {/* routes with layout */}
              {routes.filter(x => x.layout && x.alwaysAvailable).map((route : IRoute, i : number) => {
                if (user) return <RouteWithSubRoutes  key={i} {...route}/>
                return <Redirect exact to={{pathname: '/login'}} />
              })}
              {routes.filter(x => x.layout && !x.alwaysAvailable).map((route : IRoute, i : number) => {
                // if (user && !user.claims.isMember) return <Redirect exact to={{pathname: '/memberships'}} />
                if (user) return <RouteWithSubRoutes  key={i} {...route}/>
                return <Redirect exact to={{pathname: '/login'}} />
              })}
            </Switch>
          </MyLayout>
        </Switch> 
      </Router>
    </div>
  );

  function RouteWithSubRoutes(route : IRoute) {
    return (
      <Route
        path={route.path}
        render={props => <route.component {...props}/>}
        exact
      />
    );
  }
}

export default App;
