import React from "react";
import { Row, Col, Card, Tag } from 'antd'
import DynamicCardContent from './DynamicCardContent'
import {shorten} from '../utils'
import moment from 'moment'
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

interface Item {
  [key: string]: any;
  image: string
  title: string
  description: string,
  tags?: Array<string>
}

interface Props {
  cards: Array<Item>
  click: any,
  tags?: Array<any>
}

interface Tag {
  field: string,
  icon: any,
  type: string
}

const defaultProps = {
  tags: []
}

export default function Cards (props : Props) { 
  const breakpoint = useBreakpoint() 
  props = {...defaultProps, ...props}
  return (
    <Row justify="center">
      {props.cards.map((item : Item, i : number) => {
        return (
          <Col key={i} span={24} >
            <div onClick={() => props.click(item)} style={{maxWidth:800, margin: 'auto'}}>
              <Card
                hoverable
                style={{ width: '100%', margin: "auto", marginTop: 5, marginBottom: 5}}
              >
                <DynamicCardContent 
                  image={item.image} 
                  title={item.title} 
                  description={shorten(item.description, 200)}
                >
                  <Row className={'ant-card-meta-description'} style={{paddingTop: 5, width: breakpoint.sm ? '100%' : '115%'}}>
                    {props.tags && props.tags.map((tag : Tag, i : number) => {
                      let out
                      if(tag.type === 'string') {
                        let s : string | undefined = item[tag.field]
                        if(!s) return
                        out = s
                      }
                      if(tag.type === 'date') {
                        let d : moment.Moment= moment(item[tag.field])
                        if(!d) return
                        out = d.format('DD/MM/YYYY')
                      }
                      if(tag.type === 'array') {
                        let a : Array<string> = item[tag.field]
                        if(!a) return
                        out = <div>{a.map((x,i) => <Tag key={i} style={{opacity: 0.7}}>{x}</Tag>)}</div>
                      }
                      return (
                        <Row key={i} align={'middle'} style={{width: '100%'}}>
                          {tag.icon} <Col style={{paddingLeft: 5, maxWidth: '92%'}}>{out}</Col>
                        </Row>
                      )
                    })}
                  </Row>
                </DynamicCardContent>
              </Card>
            </div>
          </Col>)
      })}
    </Row>
  )
}


  
